// Import everything from autoload folder
import './autoload/_bootstrap.js'; import './autoload/ada-compliance.js'; // eslint-disable-line

// Import local dependencies
//import './plugins/lazyload';
import './plugins/modernizr.min';
import './plugins/google-map';
import './plugins/woocommerce';
import 'slick-carousel';
import 'jquery-match-height';
import objectFitImages from 'object-fit-images';
import { Fancybox } from '@fancyapps/ui';
// import '@fancyapps/fancybox/dist/jquery.fancybox.min';
// import ScrollOut from 'scroll-out';
/* global FWP */
/**
 * Fit slide video background to video holder
 */
function resizeVideo() {
  let $holder = $('.videoHolder');
  $holder.each(function () {
    let $that = $(this);
    let ratio = $that.data('ratio') ? $that.data('ratio') : '16:9';
    let width = parseFloat(ratio.split(':')[0]);
    let height = parseFloat(ratio.split(':')[1]);
    $that.find('.video').each(function () {
      if ($that.width() / width > $that.height() / height) {
        $(this).css({
          width: '100%',
          height: 'auto',
        });
      } else {
        $(this).css({
          width: ($that.height() * width) / height,
          height: '100%',
        });
      }
    });
  });
}

/**
 * Scripts which runs after DOM load
 */
$(document).on('ready', function () {
  /**
   * Make elements equal height
   */
  $('.matchHeight').matchHeight();

  /**
   * IE Object-fit cover polyfill
   */
  if ($('.of-cover').length) {
    objectFitImages('.of-cover');
  }

  /**
   * Add fancybox to images
   */
  $('.gallery-item')
    .find('a[href$="jpg"], a[href$="webp"], a[href$="png"], a[href$="gif"]')
    .attr('rel', 'gallery')
    .attr('data-fancybox', 'gallery');

  Fancybox.bind('[data-fancybox]');

  /**
   * Remove placeholder on click
   */
  const removeFieldPlaceholder = () => {
    $('input, textarea').each((i, el) => {
      $(el)
        .data('holder', $(el).attr('placeholder'))
        .on('focusin', () => {
          $(el).attr('placeholder', '');
        })
        .on('focusout', () => {
          $(el).attr('placeholder', $(el).data('holder'));
        });
    });
  };

  removeFieldPlaceholder();

  $(document).on('gform_post_render', () => {
    removeFieldPlaceholder();
  });

  /**
   * Scroll to Gravity Form confirmation message after form submit
   */
  $(document).on('gform_confirmation_loaded', function (event, formId) {
    let $target = $('#gform_confirmation_wrapper_' + formId);
    if ($target.length) {
      $('html, body').animate({ scrollTop: $target.offset().top - 50 }, 500);
      return false;
    }
  });

  // Smooth scroll to anchor
  $(document).on('click', '.js-smooth-scroll', function (e) {
    e.preventDefault();
    e.stopPropagation();

    let anchor = $(this).attr('href');
    let $header = $('header.header');

    if ($(anchor).length) {
      $('html, body').animate(
        { scrollTop: $(anchor).offset().top - $header.height() - 20 },
        500
      );
      return false;
    }
  });

  /**
   * Hide gravity forms required field message on data input
   */
  $('body').on(
    'change keyup',
    '.gfield input, .gfield textarea, .gfield select',
    function () {
      let $field = $(this).closest('.gfield');
      if ($field.hasClass('gfield_error') && $(this).val().length) {
        $field.find('.validation_message').hide();
      } else if ($field.hasClass('gfield_error') && !$(this).val().length) {
        $field.find('.validation_message').show();
      }
    }
  );

  /**
   * Override bootstrap submenu toggle
   */
  $('.navbar .menu-item-has-children').hover(
    function () {
      if ($(window).width() > 991) {
        $(this).addClass('show');
        alignMenuDropdown($(this).children('.dropdown-menu'));
      }
    },
    function () {
      if ($(window).width() > 991) {
        $(this).removeClass('show');
      }
    }
  );

  // Prevent dropdown menu opening out of window
  function alignMenuDropdown($dropdown) {
    if ($dropdown === undefined || !$dropdown.length) {
      return false;
    }
    $dropdown.toggleClass(
      'dropdown-menu-left',
      $dropdown.offset().left + $dropdown.width() > $(window).width()
    );
  }

  // Dropdown menu mobile toggle
  $('.js-dropdown-toggle').bind('click', function (e) {
    e.preventDefault();
    e.stopPropagation();

    if ($(window).width() <= 1199) {
      $(this).closest('.dropdown').toggleClass('show');
    }
  });

  // Mobile menu return to first level
  $('.js-dropdown-back').on('click', function (e) {
    e.preventDefault();
    e.stopPropagation();
    $(e.target).closest('.dropdown').removeClass('show');
  });

  // Lock body on menu opened
  $('#primary-menu')
    .on('show.bs.collapse', function () {
      $('body').addClass('lock');
    })
    .on('hidden.bs.collapse', function () {
      $('body').removeClass('lock');
    });

  // Lock body on mini-cart opened
  if ($(window).width() <= 767) {
    $('.header__cart')
      .on('show.bs.dropdown', function () {
        $('body').addClass('lock');
      })
      .on('hidden.bs.dropdown', function () {
        $('body').removeClass('lock');
      });
  }
  /**
   * Close responsive menu on orientation change
   */
  $(window).on('orientationchange', function () {
    // jQuery code goes here
  });

  // Header search
  $(document).on('click', '.js-search-btn', function (e) {
    e.preventDefault();
    e.stopPropagation();
    $(this)
      .closest('.js-search-open')
      .addClass('is-open')
      .find('input')
      .focus();
  });

  $(document).on('click', function (e) {
    if ($(e.target).closest('.js-search-open').length === 0) {
      $(document).find('.js-search-open').removeClass('is-open');
    }
  });

  // Slide toggle
  $(document).on('click', '.js-slider-toggle', function (e) {
    e.preventDefault();
    $(this).find('.fas').toggleClass('fa-arrow-left');
    $(this).siblings('.js-mobile-slide').toggleClass('is-active');
  });

  // Alt menu shown calculate height
  $('#alt-menu').on('shown.bs.dropdown', function () {
    let $altMenuList = $(this).find('.js-alt-menu-list');

    $altMenuList.find('>li>ul').each(function (i, el) {
      if ($altMenuList.outerHeight() < $(el).outerHeight()) {
        $altMenuList.css('height', $(el).outerHeight());
      }
    });
  });

  // FacetWP show labels
  $(document).on('facetwp-loaded', function () {
    $('.facetwp-facet').each(function () {
      let facet = $(this);
      let facet_name = facet.attr('data-name');
      let facet_type = facet.attr('data-type');
      let facet_label = FWP.settings.labels[facet_name];
      if (facet_type !== 'pager') {
        if (
          facet.closest('.facet-wrap').length < 1 &&
          facet.closest('.facetwp-flyout').length < 1
        ) {
          facet.wrap('<div class="facet-wrap"></div>');
          facet.before('<h3 class="facet-label">' + facet_label + '</h3>');
        }
      }
    });

    // Scroll to top
    let $facetTemplate = $('.facetwp-template');
    if ($facetTemplate.length && $('body').hasClass('scrolling')) {
      let headerHeight = $('.header').outerHeight() ?? 200;
      $('html, body').animate(
        { scrollTop: $facetTemplate.offset().top - headerHeight - 20 },
        1000
      );
    }

    $(document).find('.matchHeight').matchHeight();
  });

  slidersInit();
  sliderMobileInit();
  resizeVideo();
});

/**
 * Scripts which runs after all elements load
 */
$(window).on('load', function () {
  // jQuery code goes here

  let $preloader = $('.preloader');
  if ($preloader.length) {
    $preloader.addClass('preloader--hidden');
  }

  sliderMobileInit();
});

/**
 * Scripts which runs at window resize
 */
$(window).on('resize', function () {
  // jQuery code goes here
  slidersInit();
  sliderMobileInit();
  resizeVideo();
});

/**
 * Scripts which runs on scrolling
 */
let lastScrollTop = 0;
$(window).on('scroll', function () {
  // jQuery code goes here
  let st = $(this).scrollTop();
  $('body')
    .toggleClass('scrolling-top', st < lastScrollTop)
    .toggleClass('scrolling-bottom', st > lastScrollTop)
    .toggleClass('scrolling', st !== 0);
  if (st === 0) $('body').removeClass('scrolling-top scrolling-bottom');
  lastScrollTop = st;
});

// Update LazyLoad images before Slide change
$('.slick-slider').on('beforeChange', function () {
  $('.matchHeight').matchHeight();
});

function slidersInit() {
  $(document)
    .find('.js-usps:not(.slick-initialized)')
    .slick({
      dots: false,
      arrows: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      swipeToSlide: true,
      speed: 1000,
      autoplay: true,
      autoplaySpeed: 3000,
      mobileFirst: true,
      responsive: [
        {
          breakpoint: 1200,
          settings: 'unslick',
        },
      ],
    });
}

function sliderMobileInit() {
  $(document)
    .find('.js-slider-mobile:not(.slick-initialized)')
    .slick({
      dots: false,
      arrows: false,
      slidesToShow: 2,
      slidesToScroll: 1,
      swipeToSlide: true,
      speed: 1000,
      autoplay: false,
      autoplaySpeed: 3000,
      mobileFirst: true,
      responsive: [
        {
          breakpoint: 1200,
          settings: 'unslick',
        },
      ],
    });

  $(document)
    .find('.js-products-mobile ul.products:not(.slick-initialized)')
    .slick({
      dots: false,
      arrows: false,
      slidesToShow: 1.15,
      slidesToScroll: 1,
      swipeToSlide: true,
      speed: 1000,
      autoplay: false,
      mobileFirst: true,
      infinite: false,
      centerMode: true,
      centerPadding: '0px',
      responsive: [
        {
          breakpoint: 481,
          settings: 'unslick',
        },
      ],
    });
}
